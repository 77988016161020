<template>
  <div class="about">
    <div class="about-box">
      <h1 class="text-h5 white--text text-center mt-5 mb-5">
        Stay productive with Pomodorus - the efficient time management app
      </h1>
      <v-row no-gutters>
        <v-col cols="12" md="12">
          <v-card dark class="pa-4">
            <v-card-title>What is Pomodorus?</v-card-title>
            <p>
              Pomodorus is a customizable time management application for mobile
              and desktop. Its scope is to help anyone procrastinate less and
              focus more on daily tasks - whether personal or professional.
              Inspired by the well-known “pomodoro” technique, Pomodorus makes
              completing tasks more efficient and less stressful.
            </p>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters>
        <v-col cols="12" md="6">
          <v-card dark class="pa-4">
            <v-card-title>Steps to use the Pomodorus:</v-card-title>
            <p>
              Using the Pomodorus time management app is extremely easy. All you
              have to do is:
            </p>
            <ul>
              <li>Think of a task you need to get done</li>
              <li>Set a timeframe by adding “Add task”</li>
              <li>Name your task and hit the “Start” button on the timer</li>
              <li>Work on your task until the timer rings (25 min)</li>
              <li>Take a short 5 min Break</li>
              <li>After 4 Cycles take a 15 min Break</li>
            </ul>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card dark class="pa-4">
            <v-card-title> Main features of the Pomodorus: </v-card-title>
            <ul>
              <li>
                Personalized time intervals based on your personal preferences
              </li>
              <li>Audio feature/notification once the timer runs out</li>
              <li>Radio with Top Work Playlists</li>
              <li>
                Color coding features for easy visualization between work and
                reset time
              </li>
              <li>User-friendly, responsive design for mobile & desktop</li>
            </ul>
          </v-card>
        </v-col>
        <v-col cols="12" md="12">
          <div class="buy-me-coffe">
            <a target="_blank" href="https://www.buymeacoffee.com/igorc">
              <img src="../assets/img/buymeacoffe.png" alt="Buy Me A Coffe">
            </a>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import isMobileMixin from "../mixins/isMobileMixin";

export default {
  name: "About",
  data() {
    return {
      bodyHeight: false
    }
  },
  mixins: [isMobileMixin],
  mounted() {
    this.isMobile() ?
    this.$root.$emit('bodyHeight', this.bodyHeight) : ''
  },
  destroyed(){
    this.isMobile() ?
    this.$root.$emit('bodyHeight', this.bodyHeight = true) : ''
  }
};
</script>


<style lang="scss" scoped>
h1 {
  color: #cc2215 !important;
}

.about {
  display: flex;
  align-items: center;
  height: 100%;
}
.v-card {
  border-radius: 20px;
  height: 100%;
  &__title {
    padding-top: 0px;
    padding-left: 0px;
    padding-bottom: 8px;
    color: #cc2215;
  }
  p {
    margin-bottom: 0px;
    font-size: 15px;
  }
}
.col-6,
.col-12 {
  padding: 12px !important;
}
.buy-me-coffe {
  text-align: center;
  img {
    width: 170px;
  }
}
</style>
